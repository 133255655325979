<template>
    <div>
        <div id="homeImageWrapper">
            <b-container fluid>
                <b-row>
                    <b-carousel
                        id="homeCarousel"
                        indicators
                        fade
                        img-height="650"
                        no-hover-pause
                    >
                    <b-carousel-slide>
                        <template v-slot:img>
                                <div id="homeImg1" style="position: relative; width: 100%; height: 100%">
                                    <img
                                        class="d-block img-fluid w-100"
                                        src="../../public/images/mr_new1.jpg"
                                        alt="image slot"
                                        style="position: absolute; top: -20%; width: 100%; height: auto;"
                                    >
                                </div>
                            </template>   
                        </b-carousel-slide>
                        <b-carousel-slide>
                            <template v-slot:img>
                                <div id="homeImg3" style="position: relative; width: 100%; height: 100%">
                                    <img
                                        class="d-block img-fluid w-100"
                                        src="../../public/images/mr_new2.jpg"
                                        alt="image slot"
                                        style="position: absolute; top: -10%; width: 100% !important; max-width: initial; height: auto;"
                                    >
                                </div>
                            </template>
                        </b-carousel-slide>
                        <b-carousel-slide>
                            <template v-slot:img>
                                <div id="homeImg3" style="position: relative; width: 100%; height: 100%">
                                    <img
                                        class="d-block img-fluid w-100"
                                        src="../../public/images/mr_new3.jpg"
                                        alt="image slot"
                                        style="position: absolute; top: -10%; width: 100% !important; max-width: initial; height: auto;"
                                    >
                                </div>
                            </template>
                        </b-carousel-slide>
                        <b-carousel-slide>
                            <template v-slot:img>
                                <div id="homeImg3" style="position: relative; width: 100%; height: 100%">
                                    <img
                                        class="d-block img-fluid w-100"
                                        src="../../public/images/mr5_new.jpg"
                                        alt="image slot"
                                        style="position: absolute; top: -10%; width: 100% !important; max-width: initial; height: auto;"
                                    >
                                </div>
                            </template>
                        </b-carousel-slide>
                        <b-carousel-slide>
                            <template v-slot:img>
                                <div id="homeImg3" style="position: relative; width: 100%; height: 100%">
                                    <img
                                        class="d-block img-fluid w-100"
                                        src="../../public/images/R62_9605-1.jpg"
                                        alt="image slot"
                                        style="position: absolute; top: -10%; width: 100% !important; max-width: initial; height: auto;"
                                    >
                                </div>
                            </template>
                        </b-carousel-slide>
                        <b-carousel-slide> 
                        <template v-slot:img>
                                <div id="homeImg3" style="position: relative; width: 100%; height: 100%">
                                    <img
                                        class="d-block img-fluid w-100"
                                        src="../../public/images/R62_9382-1.jpg"
                                        alt="image slot"
                                        style="position: absolute; top: -10%; width: 100%; height: auto;"
                                    >
                                </div>
                            </template>
                        </b-carousel-slide>
                        <b-carousel-slide> 
                        <template v-slot:img>
                                <div id="homeImg2" style="position: relative; width: 100%; height: 100%">
                                    <img
                                        class="d-block img-fluid w-100"
                                        src="../../public/images/MR at GC.jpg"
                                        alt="image slot"
                                        style="position: absolute; top: -10%; width: 100%; height: auto;"
                                    >
                                </div>
                            </template>
                        </b-carousel-slide>
                    </b-carousel>
                    <!-- <b-img fluid-grow src="./images/Midnight-Rodeo-Night-Sky.png" style="bottom: 0; position: absolute"/> -->
                </b-row>
            </b-container>
            <div id="homeImageOverlay">
                <div id="homeImageOverlayTop"></div>
                <div id="homeImageOverlayLeft"></div>
                <div id="homeImageOverlayRight"></div>
                <div id="homeImageOverlayBottom"></div>
            </div>
        </div>
        <div id="homeTestimonials" style="display: none">
            <!-- Use Bootstrap Card for this -->
            This is a placeholder for Testimonial summaries from some of our clients.
        </div>
        <div id="homeShows">
            <h1>Shows</h1>
            <b-table id="homeShowsTable" striped hover :items="shows" :fields="showFields" :per-page="5"></b-table>
        </div>
        <div id="homeVideo" style="margin: 50px;">
            <h1 style="text-align: center;">"Kansas City's Best Country Cover Band"</h1>
            <div style="font-style: italic;">Midnight Rodeo, out of Kansas City Missouri,
                Offers Great Top 40 Country Music from current artists such as Luke Combs,
                Chris Stapleton, Thomas Rhett... All your favorites from the radio along
                with some timeless classics that never seem to get old. Throw in a touch
                of Southern Rock and Blues... and you have... MIDNIGHT RODEO.</div>
            <div style="width: 90%; max-width: 1024px; margin: auto;">
                <b-embed
                    type="iframe"
                    aspect="16by9"
                    src="https://www.youtube.com/embed/NM69LnKwv30"
                    allowfullscreen
                ></b-embed>
            </div>
        </div>
    </div>
</template>

<style scoped>
* {
    outline: none !important;
}
    #homeImageWrapper {
        height: 650px;
        position: relative;
        background-color: black;
        overflow: hidden;
    }

   #homeCarousel {
        height: 650px;
        position: relative;
        width: 100%;
    }

    #homeImg1, #homeImg2, #homeImg3, #homeImg4{
        position: relative;
        height: 650px !important;
        width: 100%;
    }

    @media (min-width: 1450px) {
        #homeImageWrapper, #homeCarousel, #homeImg1, #homeImg2, #homeImg3, #homeImg4 {
            height: 800px !important;
        }
    }

    #homeImageOverlay {
        height: 100%;
        position: absolute;
        top: 0;
        width: 100%;
    }

    #homeImageOverlay div {
        position: absolute;
        z-index: 9;
    }

    #homeImageOverlayLeft, #homeImageOverlayRight {
        height: 100%;
        width: 125px;
    }

    #homeImageOverlayLeft {
        left: 0;
        background: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
    }

    #homeImageOverlayRight {
        right: 0;
        background: linear-gradient(270deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
    }

    #homeImageOverlayBottom {
        width: 100%;
        height: 125px;
        background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
        bottom: 0;
    }

    #homeImageOverlayTop {
        width: 100%;
        height: 125px;
        background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
        top: 0;
    }

    #homeTestimonials {
        width: 250px;
        position: absolute;
        top: 150px;
        right: 50px;
        text-align: right;
    }

    #homeShowsTable tr {
        font-family: 'Roboto Condensed', sans-serif;
        font-weight: bold !important;
        text-transform: uppercase;
        font-size: 1.75em !important;
    }

@media (max-width: 940px) {
  #homeImageWrapper, #homeCarousel, #homeImg1, #homeImg2, #homeImg3, #homeImg4 {
    height: 400px !important;
  }

  #homeImageOverlayTop,
  #homeImageOverlayBottom {
    height: 50px;
  }

  #homeImageOverlayLeft,
  #homeImageOverlayRight {
    width: 50px;
  }
}

@media (max-width: 830px) {
  #homeImageWrapper, #homeCarousel, #homeImg1, #homeImg2, #homeImg3, #homeImg4 {
    height: 250px !important;
  }

  #homeShowsTable tr {
      font-size: 1em !important;
  }
}
</style>

<script>
import axios from 'axios'
// import json from './json/shows.json'

var showDataURL = 'https://api.midnightrodeomusic.com/shows.php'

var shows

export default {
  data () {
    return {
      showFields: [
        {
          key: 'date_start',
          label: 'Date',
          formatter: value => {
            const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC']
            var startDate = new Date(value)
            return months[startDate.getUTCMonth()] + ' ' + (startDate.getUTCDate()) + ', ' + startDate.getUTCFullYear()
          }
        },
        {
          key: 'custom_ScMWDH',
          label: 'Venue',
          formatter: value => {
            var decodeHTML = function (html) {
              var txt = document.createElement('textarea')
              txt.innerHTML = html
              return txt.value
            }

            return decodeHTML(value)
          }
        },
        {
          key: 'custom_xGm53T',
          label: 'City'
        },
        {
          key: 'time_start',
          label: 'Time',
          formatter: (value, key, item) => {
            if (item.time_start === '') { return 'TBA' }

            var startDate = new Date('1970-01-01 ' + value)
            var dd = 'AM'
            var hh = startDate.getHours()
            var m = startDate.getMinutes()
            var h = hh
            if (h >= 12) {
              h = hh - 12
              dd = 'PM'
            }
            if (h === 0) {
              h = 12
            }
            m = m < 10 ? '0' + m : m
            return h + ':' + m + ' ' + dd
          }
        }
      ],
      shows
    }
  },
  methods: {
    fetch () {
      axios.get(showDataURL)
        .then((response) => {
          this.shows = response.data
        })
    }
  },
  mounted () {
    this.fetch()
  }
}

</script>
